/** Live TV **/
#live-tv {
  /* background: url(img/WhiteTexture-Background.jpg) center center no-repeat; */
  background-size: cover;
  padding: 50px 0;
  position: relative;
}

#live-tv iframe {
  padding: 0;
  border: 3px solid #099bd8;
  border-radius: 15px 15px 0px 15px;
  box-shadow: 2px 2px 2px rgba(1, 1, 1, 0.5);
}

#live-tv h3 {
  font-family: "Nunito Sans", sans-serif;
  color: white;
  font-size: 2rem;
}

/** Packages Styling **/

#bizzll-home #packages h2 {
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  color: #c7392b;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 800;
  padding-bottom: 5px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 30px;
}

.homeAutoSearch > .ant-select-selector {
  height: 43px !important;
}

@media (max-width: 500px) {
  #live-tv iframe {
    width: 100%;
  }
}

.free-ad-container {
  width: 30%; /* Ocupa el 30% del ancho en pantallas grandes */
  padding-top: 30%; /* Hace que el contenedor sea cuadrado */
  position: relative;
  margin-bottom: 20px; /* Espacio entre elementos cuando se apilan verticalmente */
}

.free-ad-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media query para pantallas más pequeñas */
@media (max-width: 768px) {
  .free-ad-container {
    width: 100%; /* En pantallas pequeñas, ocupa todo el ancho */
    padding-top: 100%; /* Mantiene la proporción cuadrada */
  }
}
