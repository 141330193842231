//* Listing Page Style

#bizzll-cities {
  #cities-listing {
    display: flex;
    flex-wrap: wrap;

    .listing-item {
      width: 250px;
      // margin: 0 10px 30px;
      border-radius: 5px;
      overflow: hidden;
      background: white;
      border: 2px solid #0099d9;
      border-radius: 15px 15px 0 15px;
      box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);

      .cover-img {
        background-color: #8821ca;
        background-position: 50%;
        background-size: cover;
        height: 180px;
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid $lt-grey;

        .image {
          background-position: 50%;
          background-size: cover;
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: all 0.3s;
        }
      }

      .city-name {
        padding: 10px;
        text-align: center;
        box-shadow: 0 -10px 5px rgba(1, 1, 1, 0.3);
        position: relative;
        z-index: 1;

        h3 {
          margin: 0;
          font-size: 1.2rem;
        }
      }

      &:hover {
        box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%);

        .cover-img .image {
          transform: scale(1.05);
        }
      }
    }
  }

  @include tablet-below {
    #cities-listing {
      justify-content: center;
    }

    .page-sidebar {
      display: none;
    }

    .page-content {
      width: 100%;
    }
  }

  @include mobile-size {
    #cities-listing {
      flex-direction: column;
      align-items: center;

      .listing-item {
        margin-bottom: 50px;
        width: 100% !important;
        max-width: 500px;

        .cover-img {
          width: 100% !important;
        }
      }
    }
  }
}

//* Internal Page Style

#internal-city {
  margin-bottom: -60px;

  .inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .internal-sidebar {
      width: 15%;
    }

    .cityListItem-item {
      width: 60%;

      .city-btns {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .btn-wrapper {
          width: 45%;

          button {
            width: 100% !important;
            display: block;
            white-space: pre-wrap !important;
          }
        }
      }
    }
  }

  .featured-slider {
    margin-top: 60px;
  }

  @include tablet-below {
    .internal-sidebar {
      display: none;
    }

    .cityListItem-item {
      width: 100% !important;
    }
  }

  @include mobile-size {
    .city-btns {
      flex-direction: column;
      align-items: center;

      .btn-wrapper {
        width: 100% !important;

        button {
          margin-bottom: 20px;
        }
      }
    }
  }
}
