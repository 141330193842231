.featured-slider {
  background: url(../../assets/css/img/Citybackground.png) center no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .ant-carousel {
    position: relative;

    .slick-slide {
      .inner-slide {
        display: flex;
        padding: 7% 10%;
        width: 100%;
        max-width: 1500px;
        margin: 0 auto;
        justify-content: space-between;

        .text {
          max-width: 60%;
          width: 100%;
          padding: 2% 5% 2% 5%;
          background: rgba(255, 255, 255, 0.85);
          margin-right: 30px;
          border-radius: 15px 0px 0px 15px;

          h3 {
            font-family: "Nunito Sans", sans-serif;
            font-weight: 700;
            color: #233a59;
            margin-bottom: 15px;
            font-size: 2.9rem;
            line-height: 1.1em;
          }

          p {
            font-family: "Nunito Sans", sans-serif;
            font-size: 1.5rem;
            line-height: 35px;
            color: #233a59;

            strong {
              font-weight: 800;
            }
          }

          button {
            background: #40a2df;
            border: 2px solid #1976bc;
            padding: 10px 30px;
            /* display: block; */
            text-align: center;
            border-radius: 5px;
            font-size: 1.5rem;
            color: white;
            cursor: pointer;
            font-weight: 600;
            text-transform: uppercase;
            display: none;

            &:hover {
              color: black;
              background: white;
            }
          }
        }

        .slide-img {
          max-width: 35%;
          width: 100%;
          border-radius: 0px 15px 15px 0px;
          overflow: hidden;
          -webkit-box-shadow: 2px 2px 2px rgba(1, 1, 1, 0.5);
          box-shadow: 2px 2px 2px rgba(1, 1, 1, 0.5);
        }
      }
    }

    .slick-dots li {
      border: #233a59 2px solid;
    }

    .slick-prev {
      left: 5%;
    }

    .slick-next {
      right: 5%;
    }

    .slick-prev,
    .slick-next {
      color: #000;
      font-size: 60px;
      transition: 0.3s all;
      height: 60px;
      width: 60px;
      z-index: 10;

      &:hover {
        color: #fff !important;
        opacity: 0.7;
      }
    }
  }

  @include mobile-size {
    .inner-slide {
      flex-direction: column-reverse;

      .text,
      .slide-img {
        max-width: 100% !important;
      }

      .slide-img {
        margin-bottom: 25px;
      }

      .text {
        text-align: center;

        h3 {
          font-size: 2rem !important;
          line-height: 1.2em;
        }

        p {
          font-size: 1.2rem !important;
          line-height: 1.5em;
        }
      }
    }

    .slick-prev {
      left: 1% !important;
    }

    .slick-next {
      right: 1% !important;
    }
  }
}
