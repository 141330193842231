#bizzll-business-profile {
  #bizzll-profile-header {
    height: initial;
    display: block;
    width: 100%;
    background: white;
    padding-bottom: 15px;
    margin-bottom: 30px;
    box-shadow: 0 1px 1px rgba(1, 1, 1, 0.3);

    .profile-header-cover {
      max-height: 380px;
      position: relative;
      overflow: hidden;

      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: 100% !important;
      }

      &.no-cover {
        .image {
          background: #8620ce url(../../assets/css/img/geopattern.png) center
            repeat !important;
        }
      }
    }

    .header-reviews {
      z-index: 2;
      position: absolute;
      bottom: 80px;
      right: 0;
      display: flex;
      color: white;
      font-weight: 700;
      font-size: 12pt;
      align-items: center;
      background: rgba(0, 0, 0, 0.7);
      padding: 10px 20px;
      border-radius: 8px;

      .reviews-separator {
        margin: 0 10px;
      }

      .ant-rate {
        font-size: 1rem !important;
      }

      .ant-rate-star {
        margin-right: 4px !important;

        svg {
          font-size: 1.2rem !important;
        }
      }
    }

    .profile-header-content {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      position: relative;
      z-index: 10;
      width: 100%;
      margin: -60px auto 0;
      font-family: "Open Sans", sans-serif;

      .profile-header-image {
        width: 120px;
        height: 120px;
        display: block;
        padding: 15px;
        background: rgba(139, 30, 202, 0.8);
        border-radius: 15px 15px 0 15px;
        overflow: hidden;
        margin-right: 15px;

        a {
          border-radius: 15px 15px 0 15px;
          background: white;
          display: block;
          overflow: hidden;
          transition: 0.3s all;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .business-name {
        h2 {
          color: #8620ce;
          letter-spacing: 0;
          font-size: 1.1rem;
          text-transform: none;
          display: flex;
          align-items: center;
          font-weight: 600;
          margin-bottom: 0;
        }

        span {
          font-size: 10pt;
          font-style: italic;
          color: #818181;
          display: block;
          margin-left: 10px;
        }
      }

      .address {
        display: flex;
        align-items: center;
        color: #818181;

        span {
          font-size: 0.8rem;
          font-weight: 600;
        }

        svg {
          font-size: 1rem;
          color: $red;
          margin-right: 5px;
        }
      }

      .networking {
        justify-self: end;
        display: flex;
        margin-left: auto;

        .ant-btn-primary[disabled] {
          border-color: $hd-grey !important;
          color: $red !important;
        }

        button {
          margin-left: 10px;
        }

        .message-btn,
        .reportTextContainer {
          button {
            background: $red;
            color: white;

            &:hover {
              color: $red;
              background: white;
            }
          }
        }

        .follow-btn {
          button {
            border-color: $purple;
            background: none;
            color: $purple;

            &:hover {
              background: $purple;
              color: white;
            }
          }
        }
      }
    }
  }

  .profile-page-widgets {
    .widget-info-box {
      .button {
        padding: 15px;
        border-radius: 15px 15px 0 15px;
        border: 4px solid white;
        box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);
        overflow: hidden;
        display: flex;
        transition: 0.3s all;
        color: white !important;
        min-height: 70px;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .profile-widget-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
          width: 100%;
          text-align: left;
          font-weight: 600;

          .title-txt {
            padding-bottom: 5px;
            border-bottom: 3px solid white;
            width: 135px;
          }

          .title-icon {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            display: block;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 5px;
            overflow: hidden;
          }
        }

        .profile-widget-info {
          display: block;
          width: 100%;
          text-align: right;
          font-size: 12pt !important;
          line-height: 20px;
        }

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }

  @include mobile-size {
    #bizzll-profile-header {
      .profile-header-cover {
        height: 150px;
      }

      .profile-header-content {
        text-align: center;
        display: block;
        margin-top: -40px !important;

        .profile-header-image {
          margin-bottom: 10px;
          width: 90px;
          height: 90px;
        }

        .header-reviews {
          bottom: auto !important;
          top: -5px;
          font-size: 10pt !important;
          padding: 5px 15px !important;
        }

        .networking {
          button {
            font-size: 0.8rem !important;
          }
        }
      }
    }

    .profile-inner {
      #bizzll-profile-submenu {
        .profile-submenu {
          width: 100%;

          ul {
            width: 100%;
            justify-content: center;
            padding: 0 !important;

            a {
              font-size: 0.8rem !important;
            }
          }
        }

        .follower-count {
          display: none;
        }
      }

      #profile-page-content {
        display: block !important;

        .bizzll-profile-page-inner {
          width: 100% !important;

          .post-content {
            padding: 0 !important;

            .all-advertisements {
              margin-top: 0 !important;

              .single-post-container {
                width: 100% !important;
              }
            }
          }
        }

        .profile-page-widgets {
          width: 100% !important;
        }
      }
    }
  }
}
