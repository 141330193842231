#checkout-page {
  .checkout-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .checkout-steps {
      width: 68%;

      .step-wrapper {
        margin-bottom: 5px;
        border: $md-grey 1px solid;
        background-color: #fafafa;
        padding: 15px;

        .step-heading {
          display: flex;
          align-items: center;

          .icon {
            background: $green;
            color: white;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
          }

          .title {
            font-weight: 700;
            font-family: $heading-font;
            font-size: 0.9rem;
            line-height: 1.3em;

            span {
              font-weight: normal !important;
              display: block;
            }
          }

          .edit-btn {
            justify-self: flex-end;
            margin-left: auto;
          }
        }

        .step-content {
          margin-top: 25px;
          padding: 10px;
          overflow: hidden;
          align-items: center;

          .ant-alert {
            margin-top: 25px;
          }

          .card-details {
            display: flex;
            margin-bottom: 30px;

            .label {
              font-family: $text-font;
              font-size: 1.1rem;
              font-weight: 700;
              margin-right: 15px;
            }
          }

          .payment-method {
            .payment-method-item {
              margin-bottom: 10px;

              label {
                font-size: 1.1rem;
                display: block;
                cursor: pointer;

                span {
                  font-size: 0.9rem;
                }

                svg {
                  font-size: 1.5rem;
                  color: $red;
                }
              }

              input {
                margin-right: 10px;
              }
            }
          }

          .fields {
            display: flex;
            margin-bottom: 15px;

            .card-element {
              padding: 10px 15px;
              display: block;
              border-radius: 8px;
              font-size: 1.1rem;
              color: $hd-grey;
              border: 1px solid $md-grey;
              transition: 0.3s all;
              margin: 0 10px;
              width: 100%;
              height: 50px;

              &.card-element-sm {
                width: 150px;
              }

              &:hover {
                border-color: $sky-blue !important;
                box-shadow: 0 0 0 1px $sky-blue !important;
              }
            }
          }
        }

        .step-summary {
          margin-top: 25px;
          padding: 10px;
          border: 1px solid $md-grey;
          background-color: white;

          .sub-heading {
            font-size: 1.1rem;
            font-weight: 700;
          }

          span {
            display: block;
          }
        }

        &.is-active {
          background-color: white !important;
        }

        &.incomplete {
          .icon {
            background-color: $sky-blue !important;
          }
        }
      }
    }

    .checkout-summary {
      width: 30%;

      .inner-content {
        background-color: white;
        border: $md-grey 1px solid;

        .summary-title {
          font-size: 1rem;
          font-family: $heading-font;
          font-weight: 700;
          text-transform: uppercase;
          padding: 15px 20px;
          border-bottom: 1px solid $lt-grey;
        }

        .cart-items-list {
          padding: 15px 20px;
          border-bottom: 1px solid $lt-grey;

          .cart-item {
            display: flex;
            justify-content: space-between;
            font-size: 1rem;
          }
        }

        .summary-section {
          padding: 15px 20px;
          border-bottom: 1px solid $lt-grey;

          .summary-row {
            display: flex;
            justify-content: space-between;

            .value {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
