/** Logo Styles **/
#site-header #logo {
  justify-self: flex-start;
  margin-right: auto;
  height: 85%;
}

#site-header #logo img {
  width: auto;
  height: 100% !important;
}

/** Button styles **/
#header-btns button {
  border-radius: 5px;
  display: inline-block;
  padding: 5px 20px;
  color: white;
  font-weight: 900;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  margin-left: 10px;
  text-transform: uppercase;
}

#header-buttons button {
  border-radius: 5px 5px 0px 5px;
  display: inline-block;
  padding: 5px 20px;
  /* color: #233a59; */
  font-weight: 900;
  cursor: pointer;
  margin-left: 10px;
  /* background: #c0e0f5; */
  color: white;
  background-color: #0099D9;
  border: 2px solid #0099D9;
  text-align: center;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
}

#header-buttons button:hover {
  box-shadow: 0px 0px 5px rgba(1, 1, 1, 0.75);
}

/** User Header Menu **/

#header-userbar {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
}

.user-avatar {
  margin: 0 5px;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid transparent;
  background: transparent;
}

/* .user-avatar .ant-popover-open {
  border: none !important;
} */

.user-avatar .ant-avatar-circle {
  background: #fcc204;
  color: #000;
  font-weight: 600;
}

#header-userbar .userbar-btns {
  border: none;
  background: none;
  padding: 0 !important;
}

#header-userbar .messages-btn {
  margin-right: 15px;
  margin-left: 0 !important;
}

#header-userbar button {
  cursor: pointer;
}

#header-userbar .userbar-btns svg {
  font-size: 1.5rem;
  color: white;
}

#header-userbar .userbar-btns svg {
  color: #c63627 !important;
}

#header-userbar .user-header-menu {
  position: absolute;
  top: 60px;
  right: 0;
  background: white;
  -webkit-box-shadow: 0 0 10px -5px rgb(0 0 0 / 50%);
  box-shadow: 0 0 10px -5px rgb(0 0 0 / 50%);
  width: 250px;
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.3s all;
}

#site-header.header--scrolled #header-userbar .user-header-menu {
  top: 50px;
}

.header--scrolled #hamburger-icon {
  color: #c63627;
}

#header-userbar .user-header-menu .user-greeting .user-menu-logo {
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 15px;
}

#header-userbar .user-menu-section {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: #bebebe 1px solid;
}

#header-userbar .user-menu-section ul {
  margin-bottom: 0;
}

#header-userbar .user-menu-section li {
  margin-bottom: 5px;
}

#header-userbar .user-menu-section li a {
  display: block;
  width: 100%;
  color: #303030;
  font-weight: 600;
}

#header-userbar .user-menu-section li a:hover {
  color: #c63627;
}

.chat-item {
  display: flex;
  cursor: pointer;
  padding: 10px;
  color: black;
  /* border-radius: 5px; */
}

.chat-item:hover {
  background-color: #e6e6e6;
}

.avatar {
  margin-right: 10px;
  /* margin-left: 10px; */
}

.latest-message {
  width: 150px;
}

.sender-name-container {
  display: flex;
}

.sender-name {
  margin-right: 10px;
}

.latest-message-text {
  font-size: 10px;
}

.message-type {
  font-size: 8px;
  padding: 1px;
  border-radius: 4px;
  margin-top: 4px;
}

.business-message {
  border: 1px solid rgb(198, 54, 39);
  color: rgb(198, 54, 39);
}

.private-message {
  border: 1px solid rgb(255, 194, 4);
  color: rgb(255, 194, 4);
}

.message-time {
  font-size: 10px;
  margin-top: 3px;
}

.unread-circle {
  font-size: 8px;
  margin-left: 35px;
}

.ant-popover {
  position: fixed;
}

.ant-popover-inner-content {
  padding: 0;
  max-height: 300px;
  overflow: auto;
}

.no-notifications {
  padding: 10px;
  font-weight: bold;
}

.no-messages {
  padding: 10px;
  font-weight: bold;
}

.like-notification {
  border: 1px solid #8620ce;
  color: #8620ce;
}

.reportBusinessTitle {
  font-weight: bold;
  font-size: 18px;
  align-items: center;
}

.flagReportIcon {
  color: #c63627;
}

.reportType {
  font-weight: bold;
  font-size: 18px !important;
}

.selectProblemText {
  font-weight: bold;
  font-size: 18px !important;
  display: block;
}

.radioGroupContainer {
  margin-top: 20px;
}

.handleBusinessReportBtnContainer {
  display: flex;
}

.submitBusinessReportBtn {
  margin-left: 10px;
}

.reportText {
  border: 1px solid #303030;
  padding: 4px;
  border-radius: 4px;
}

.message-notification-user-bar {
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin: 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
  max-width: 320px;
}

.business-notification {
  cursor: pointer;
}
