//* Listing Page Styling

#bizzll-events {
  .bizzll-events {
    display: flex;
    flex-wrap: wrap;
    transition: 0.3s all;

    .listing-item {
      width: 250px;
      margin: 0 10px 30px;
      border-radius: 5px;
      overflow: hidden;
      background: white;
      border: 2px solid #0099D9;
      border-radius: 15px 15px 0 15px;

      .cover-img {
        background-color: #8821ca;
        background-position: 50%;
        background-size: cover;
        height: 250px;
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid $lt-grey;

        .image {
          background-position: 50%;
          background-size: cover;
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: all 0.3s;
        }

        &:hover {
          .image {
            transform: scale(1.05);
          }
        }
      }

      .event-info {
        padding: 15px;

        .event-name h3 {
          margin: 0;
          text-align: center;
          font-size: 1.1rem;
          margin-bottom: 10px;
        }

        .event-description {
          border-top: 1px solid $lt-grey;
          border-bottom: 1px solid $lt-grey;
          padding: 5px;
        }

        .event-meta {
          margin: 10px 0;

          .meta-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            font-size: 0.9rem;

            .label {
              color: $lt-green;
              font-weight: 800;
            }
          }
        }
      }

      &:hover {
        box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%);
      }
    }
  }

  @include tablet-below {
    .bizzll-events {
      justify-content: center;
    }

    .page-sidebar {
      display: none;
    }

    .page-content {
      width: 100%;
    }
  }

  @include mobile-size {
    .bizzll-events {
      flex-direction: column;
      align-items: center;

      .listing-item {
        margin-bottom: 50px;
        width: 300px !important;

        .cover-img {
          width: 100% !important;
          height: 300px !important;
        }
      }
    }
  }
}

//* Single Event Page Styling
#bizzll-event {
  .page-content {
    .event-promo {
      img {
        width: 100%;
        border: 2px solid #0099D9;
        border-radius: 15px 15px 0 15px;
      }
    }
    .description {
      font-size: 1rem;
      line-height: 1.8em;
      margin-top: 20px;
    }

    .share-bar {
      background: white;
      padding: 10px;
      border-radius: 8px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 30px;

      .social-media {
        .label {
          margin-bottom: 5px;
          font-weight: 700;
          font-size: 1.1rem;
        }
      }
    }
  }

  #event-sidebar {
    .button-widget {
      padding: 0 !important;
      background: none !important;
      border: none;

      button {
        width: 100% !important;
        padding: 10px 15px;

        svg {
          margin-right: 10px;
        }
      }
    }

    .widget-box {
      h3 {
        // background: $sky-blue;
        background-color: #0099D9;
        color: white;
        text-align: center;
        text-transform: uppercase;
        padding: 10px;
        border-radius: 15px 15px 0 15px;
      }

      .meta-data {
        font-size: 1rem;
        line-height: 1.2em;
        margin: 0 10px 15px;

        .label {
          font-weight: 700;
          margin-bottom: 5px;
        }
      }

      add-to-calendar-button {
        display: flex;
        margin: auto;

        button {
          background-color: red !important;
        }
      }
    }
  }

  .about-organizer {
    width: 100%;
    margin-top: 40px;
    background: white;
    padding: 30px 15px;
    border-radius: 15px 15px 0 15px;
    border: 2px solid #0099D9;
    box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);

    .organizer-wrapper {
      display: flex;
      justify-content: space-between;

      .logo {
        width: 350px;

        img {
          width: 100%;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      .organizer-info {
        margin-left: 30px;

        h3 {
          font-size: 1.7rem;
          margin-bottom: 0;
        }

        h4 {
          font-size: 1.3rem;
        }

        .bottom-btns {
          text-align: right;

          a {
            padding: 5px 15px !important;
          }
        }
      }
    }
  }

  @include mobile-size {
    .inner-container {
      flex-direction: column;

      .page-content,
      #event-sidebar,
      .about-organizer {
        width: 100% !important;
        max-width: 600px;
        margin: 0 auto 30px;
      }

      .share-bar {
        flex-direction: column;
        align-items: center !important;
        text-align: center;

        .social-media {
          margin-bottom: 5px;
        }
      }

      .organizer-wrapper {
        flex-direction: column;
        text-align: center;
        align-items: center;

        .logo {
          width: 200px;
        }

        .organizer-info {
          margin: 0 !important;

          .bottom-btns {
            text-align: center !important;
          }
        }
      }
    }
  }
}

//* Zoom Event Styling
// #zoom-component {
//   .zmwebsdk-MuiPaper-root {
//     width: 100vw;
//     height: 100vh;
//   }
// }
