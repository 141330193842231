#order-confirmation {
  .header {
    text-align: center;

    h2 {
      margin-bottom: 10px;
    }
  }

  .order-details {
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
    background: white;
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%);
    padding: 20px;

    .products-table {
      padding: 20px;
      text-align: center;

      .row {
        display: flex;
        border-bottom: 1px solid $md-grey;
        align-items: center;

        &.labels {
          & > div {
            background-color: $lt-grey;
            font-weight: 700;
            text-transform: uppercase;
          }
        }

        &.items {
          .item {
            text-align: left;
            font-weight: 700;

            span {
              display: block;
              font-size: 1rem;
              font-weight: normal !important;
            }
          }
        }

        & > div {
          width: 25%;
          padding: 20px;
          font-size: 1.2rem;
        }
      }
    }
  }
}
