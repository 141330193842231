#site-header {
  position: sticky;
  width: 100%;
  z-index: 900;
  transition: 0.3s all;
  top: 0;
  box-shadow: 0 0 5px rgba(1, 1, 1, 0.3);
  // background: #0099D9;
  background-color: white;
  overflow: hidden;

  .header-inner {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.3s all;

    .mobile-toggle {
      display: none;
    }

    .mobile-search {
      display: none;
    }

    #header-menu {
      ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        margin: 0;

        li {
          list-style-type: none;
          text-transform: uppercase;

          label {
            font-size: 25px;
            color: #fff;
            transition: 0.3s all;
            padding: 20px 15px;
            background-color: #0099d9;
          }

          label:hover {
            transition: 0.3s all;
          }

          a:link,
          a:visited {
            display: block;
            line-height: 60px;
            padding: 0 15px;
            text-decoration: none;
            color: #0099d9;
            font-weight: 700;
            font-size: 1rem;
            font-family: "Nunito Sans", sans-serif;

            &:hover {
              // color: #fff;
            }
          }
        }
      }
    }
  }

  &.header--scrolled {
    background: white;

    .header-inner {
      height: 50px;

      #header-menu {
        ul {
          li {
            a:link,
            a:visited {
              line-height: 50px;
              color: #0099d9;
              background: #fff !important;

              &:hover {
                color: #fff !important;
                background: #0099d9 !important;
              }
            }
          }
        }
      }
    }
  }

  #header-buttons {
    .mobile-buttons {
      display: none;
    }
  }

  @include mobile-size {
    #logo {
      height: 60% !important;
      margin: 0 auto !important;
    }

    .desktop-menu {
      display: none !important;
    }

    .mobile-toggle {
      display: block !important;
      font-size: 1.5rem;
      color: $red;
      margin-right: 20px;
      cursor: pointer;
    }

    .mobile-search {
      display: block !important;
      font-size: 1.5rem;
      color: $red;
      margin-right: 20px;
      cursor: pointer;
    }

    #header-buttons {
      .desktop-buttons {
        display: none;
      }

      .mobile-buttons {
        display: block !important;
      }

      .user-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        padding: 0 !important;
        border-radius: 50% !important;
      }
    }

    &.header--scrolled {
      .mobile-toggle {
        color: $red !important;
      }

      .mobile-search {
        color: $red !important;
      }
    }
  }
}

.mobile-menu-drawer {
  .ant-drawer-body {
    padding: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;

      a:link,
      a:visited {
        display: block;
        color: black;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 8px 15px;
        border-bottom: 1px solid $lt-grey;

        &:hover {
          background: black;
          color: white;
        }
      }
    }
  }

  .secondary-mobile-menu {
    a:link,
    a:visited {
      font-weight: 300;
      font-size: 1rem;
    }
  }
}

.header-sub-menu {
  list-style-type: none;
  width: 250px;
  padding: 0;
  margin: 0;

  a:link,
  a:visited {
    padding: 10px 15px;
    display: block;
    font-size: 1rem;
    color: #0099d9;

    &:hover {
      color: white;
      background: #0099d9;
    }
  }
}

.ant-popover-inner-content {
  .header-user-menu {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        line-height: normal;

        a {
          display: block;
          color: black;
          padding: 10px 25px;
          font-family: $heading-font;

          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
