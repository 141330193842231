#page-title {
  min-height: 50px;
  padding: 40px 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 60px;
  position: relative;
  background: url(../../assets/images/TitleHeader.png) center no-repeat;
  background-size: cover;
  background-color: #3f80c4;

  .inner-container.has-search {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-text {
      width: 25%;
    }

    .directory_search {
      width: 70%;
      background: white;
      border-radius: 8px;
      overflow: hidden;

      .ant-input-group {
        display: flex;
        align-items: center;

        .ant-select {
          margin: 5px;
          border-left: 1px solid $lt-grey;

          .ant-select-selector {
            border: none;
          }
        }

        & > .ant-input {
          margin: 5px;
          border: none !important;
        }

        .ant-btn {
          padding: 0;
          display: block;
          height: 45px !important;
          width: 100px !important;
          border-radius: 0 8px 8px 0;
          overflow: hidden;
          background: $purple;
          border: $purple 2px solid;
          color: white;

          &:hover {
            background: white;
            color: $purple;
          }
        }
      }
    }
  }

  h1 {
    color: white;
    font-family: "Nunito Sans";
    font-size: 2.5rem;
    line-height: 55px;
    z-index: 100;
  }

  @include mobile-size {
    padding: 20px 0 !important;

    .inner-container {
      .title-text {
        width: 100% !important;
        font-size: 1.8rem !important;
        text-align: center;
      }

      .directory_search {
        display: none !important;
      }
    }
  }
}
