// Import Fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

// Font Variables
$text-font: "Nunito Sans", sans-serif;
$heading-font: "Inter", sans-serif;
$paragraph-size: 1.1rem;
$heading-size: 1.5rem;

// Theme Colors
$purple: #8620ce;
$sky-blue: #458dd2;
$red: #c63627;
$hd-grey: #3a3541de;
$md-grey: #cccccc;
$lt-grey: #ebebeb;
$light-bg: #f0f2f5;
$green: #05704f;
$lt-green: #93cc04;
$yellow: #fcc204;

// Container
$max-width: 1200px;

// Media Queries
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1024px;
$pc-med: 1025px;
$pc-lg: 1536px;

@mixin mobile-size {
  @media screen and (max-width: #{$mobile-max}) {
    @content;
  }
}

@mixin tablet-size {
  @media screen and (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
    @content;
  }
}

@mixin tablet-below {
  @media screen and (max-width: #{$tablet-max}) {
    @content;
  }
}

@mixin pc-size {
  @media screen and (min-width: #{$pc-med}) {
    @content;
  }
}
