.floating-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1000;
}

.hidden {
  right: -60px;
  transition: right 0.3s;
}

.floating-button .toggle-button {
  width: 42px;
  height: 42px;
  position: absolute;
  top: -15px;
  left: -15px;
  z-index: 1001;
  padding: 0 !important;
  color: #c63627;
  background-color: white;
  border-radius: 50% !important;
}