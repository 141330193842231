body {
  img {
    max-width: 100%;
    height: auto;
    width: auto;

    &.header-cover {
      width: 100%;
    }
  }

  .container {
    max-width: $max-width;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  .page-content {
    .page-wrapper {
      max-width: $max-width;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
    }

    .field-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .ant-form-item {
        width: 48%;
      }
    }

    .ant-input:focus,
    .ant-input-focused,
    .ant-select-focused .ant-select-selector,
    .ant-input-affix-wrapper-focused,
    .ant-picker-focused,
    .ant-input-number-focused {
      border-color: $sky-blue !important;
      box-shadow: 0 0 0 1px $sky-blue !important;
    }

    .ant-input {
      padding: 10px 15px;
      display: block;
      border-radius: 8px;
      font-size: 1.1rem;
      color: $hd-grey;
    }

    .ant-input-number {
      padding: 10px 15px;
      display: block;
      border-radius: 8px;
      font-size: 1.1rem;
      color: $hd-grey;
    }

    .ant-select-selector {
      height: auto !important;
      padding: 10px 15px !important;
      display: block !important;
      border-radius: 8px !important;
      font-size: 1.1rem !important;
      color: $hd-grey !important;
    }

    .ant-form-item {
      margin-bottom: 30px;
    }

    .ant-form-item-label {
      margin: 0 !important;

      label {
        font-size: 0.9rem !important;
        color: $hd-grey !important;
        font-weight: 600;
        height: auto !important;

        .ant-form-item-tooltip {
          color: $red !important;
          font-size: 1.2rem !important;
          padding-left: 5px;
        }
      }
    }
  }

  .ant-btn-primary {
    background: $red;
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
    padding: 5px 15px;
    height: auto !important;
    border: $red 2px solid;
    border-radius: 10px 10px 0 10px;
    width: auto !important;

    &:hover {
      background: white;
      color: $red;
      border-color: $red;
    }

    &:focus {
      background-color: $red;
      border-color: $red;
    }
  }

  h2.heading-separator {
    // background-image: url(../assets/images/pattern-separator.png);
    background: rgb(54, 77, 121);
    color: white;
    margin: 0;
    padding: 10px 20px;
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    place-content: center;

    img {
      width: 100px;
      margin-left: 5px;
    }
  }

  .page-with-sidebar {
    .inner-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .page-sidebar {
        width: 25%;

        .ad-space {
          margin-bottom: 20px;
        }

        .widget-box {
          background: white;
          padding: 20px;
          margin-bottom: 20px;
          border-radius: 15px 15px 0 15px;
          box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);
          border: 2px solid #0099d9;
          text-align: center;

          h3 {
            font-weight: 700;
            margin-bottom: 15px;
          }

          input,
          select {
            font-size: 1.1rem;
            border: #ebebeb 1px solid;
            padding: 5px 15px;
            margin-bottom: 15px;
          }

          input[type="text"],
          select {
            width: 100%;
          }

          .ant-select {
            .ant-select-selector {
              border-radius: 10px 10px 0 10px;
              box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);
              border-color: #8620ce;
            }

            .ant-select-arrow {
              top: 0;
              right: 0;
              margin-top: 0;
              width: 40px;
              height: 40px;
              background-color: #8620ce;
              color: #fff;
              border-radius: 0 10px 0 0;
              box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);

              .anticon {
                width: 100%;

                svg {
                  font-size: 18px;
                }
              }
            }

            .ant-select-clear {
              margin-right: 40px;
            }
          }
        }
      }

      .page-content {
        width: 70%;
      }
    }
  }

  .listing-page {
    .listing-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .results-num {
        font-size: 1rem !important;
        margin: 0 !important;
      }

      .sort-form {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        form {
          margin-right: 15px;

          span {
            margin-right: 5px;
          }
        }

        .radioVal {
          margin-right: 0 !important;
          margin-left: 0 !important;
        }

        select {
          cursor: pointer;
          font-size: 14pt;
          border: #ebebeb 1px solid;
          padding: 5px 15px;
          background-color: white;
        }
      }
    }

    @include mobile-size {
      .listing-header {
        flex-direction: column-reverse;

        .sort-form {
          margin-bottom: 15px;
          flex-direction: column;

          form {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
