//* Listing Page Styling

#bizzll-groups {
  .bizzll-groups {
    display: flex;
    flex-wrap: wrap;

    .listing-item {
      width: 250px;
      margin: 0 10px 30px;
      border-radius: 5px;
      overflow: hidden;
      background: white;
      display: block;
      color: black;

      .cover-img {
        background-color: #8821ca;
        background-position: 50%;
        background-size: cover;
        height: 250px;
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid $lt-grey;

        .image {
          background-position: 50%;
          background-size: cover;
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: all 0.3s;
        }
      }

      .group-info {
        padding: 15px;

        .group-name h3 {
          margin: 0;
          text-align: center;
          font-size: 1.1rem;
          margin-bottom: 10px;
        }

        .group-description {
          border-top: 1px solid $lt-grey;
          border-bottom: 1px solid $lt-grey;
          padding: 5px;
        }

        .group-meta {
          margin: 10px 0;

          .meta-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            font-size: 0.9rem;

            .label {
              color: $lt-green;
              font-weight: 800;
            }
          }
        }
      }

      .visit-btn {
        width: 90% !important;
        display: block;
        margin: 5px auto 15px;

        svg {
          margin-right: 5px;
        }
      }

      &:hover {
        box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%);

        .cover-img .image {
          transform: scale(1.05);
        }
      }
    }
  }

  @include tablet-below {
    .bizzll-groups {
      justify-content: center;
    }

    .page-sidebar {
      display: none;
    }

    .page-content {
      width: 100%;
    }
  }

  @include mobile-size {
    .bizzll-groups {
      flex-direction: column;
      align-items: center;

      .listing-item {
        margin-bottom: 50px;
        width: 300px !important;

        .cover-img {
          width: 100% !important;
          height: 300px !important;
        }
      }
    }
  }
}
