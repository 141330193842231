#mobile-footer-bar {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 999;
  box-shadow: 0 0 9px #0000001f;
  border: 1px solid #ffffff;
  display: none;

  .inner-component {
    display: flex;

    a {
      display: block;
      width: 25%;
      text-align: center;
      padding: 10px 0;
      font-weight: 700;
      font-size: 0.8rem;
      line-height: 1.8em;
      color: black;

      span {
        display: block;
        font-size: 1.2rem;
      }

      &:hover {
        background: $sky-blue;
        color: white;
      }
    }
  }

  @include mobile-size {
    display: block !important;
  }
}
