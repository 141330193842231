footer {
  text-align: center;
  /* background-image: url(../images/pattern.png); */
  background: rgb(54, 77, 121);
  padding: 30px 20px;
  color: #b7b7b7;
}

.footer-logo {
  width: 300px;
  margin: 0 auto 25px;
}

.footer-menu {
  margin-bottom: 25px;
}

footer a {
  color: #b7b7b7;
  font-size: 1.5rem;
  text-decoration: none;
}
