#home-hero {
  background-color: black;
  color: white;
  position: relative;
  overflow: hidden;

  .hero-container {
    background-size: cover;
    background-position: center;

    .hero-inner {
      padding: 30px 0;
      justify-content: space-between;
      align-items: center;
      background: rgb(0, 0, 0, 0.5);

      .intro-text {
        z-index: 20;
        text-align: center;
        font-family: "Nunito Sans", sans-serif;

        h2 {
          font-size: 3rem;
          font-weight: 800;
          color: #fcc204;
          line-height: 1.2em;
          text-shadow: #fcc204 0 0 14px;
        }

        h3 {
          font-size: 2.3rem;
          font-weight: 900;
          color: white;
          margin-bottom: 0;
        }

        p {
          font-weight: 600;
          font-size: 1.7rem;
          line-height: 35px;
          margin-bottom: 40px;

          img {
            width: 100px;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .hero-cta {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: $max-width;
        margin: 0 auto;
        position: relative;
        text-align: center;
        align-items: center;

        .join-movement {
          width: 100%;
          margin-bottom: 30px;
        }

        .hero-btns {
          width: 55%;
          display: flex;
          flex-wrap: wrap;
          margin-right: 30px;

          span {
            display: block;
            width: 50%;
            margin-top: 20px;
            text-align: right;

            button {
              display: flex;
              background: rgba(0, 0, 0, 0.3);
              border: 2px solid white;
              padding: 10px 30px;
              cursor: pointer;
              font-weight: 800;
              font-size: 1.3rem;
              color: white;
              border-radius: 10px 10px 0px 10px;
              margin-right: 15px;
              margin-left: auto;
              vertical-align: middle;
              font-family: "Nunito Sans", sans-serif;
              backdrop-filter: blur(2px);
              align-items: center;
              justify-content: center;
              -webkit-box-shadow: 0 0 20px 0 rgb(0 0 0 / 50%);
              box-shadow: 0 0 20px 0 rgb(0 0 0 / 50%);

              img {
                width: 65px;
                padding: 0 3px;
              }

              svg {
                margin-right: 5px;
              }

              &:hover {
                background: white;
                color: #0099d9;
                box-shadow: 0px 0px 5px rgba(1, 1, 1, 0.75);
              }
            }

            &:nth-child(odd) {
              button {
                margin-right: auto !important;
                margin-left: 0 !important;
              }
            }
          }
        }

        #home-search {
          background: rgba(255, 255, 255, 0.3);
          backdrop-filter: blur(10px);
          padding: 20px 35px;
          border-radius: 15px 15px 0px 15px;
          text-align: center;
          -webkit-box-shadow: 0 0 20px 0 rgb(0 0 0 / 70%);
          box-shadow: 0 0 20px 0 rgb(0 0 0 / 100%);
          width: 500px;

          .form-heading {
            font-size: 2.5rem;
            line-height: 55px;
            font-weight: 900;
            margin-bottom: 30px;
            font-family: "Nunito Sans", sans-serif;
            text-shadow: black 0 0 15px;

            span {
              display: block;
              font-size: 1.5rem;
              line-height: 1.2em;
              font-weight: normal !important;
              text-shadow: black 0 0 15px;
            }
          }

          label {
            width: 100%;
            background: white;
            display: block;
            overflow: hidden;
            margin-bottom: 20px;
            font-size: 1.2rem;
            font-family: "Nunito Sans", sans-serif;
            border-radius: 10px 10px 0px 10px;
            box-shadow: 3px 3px 1px rgba(1, 1, 1, 0.3);

            & > span {
              color: #414141;
              padding: 10px 20px;
              display: inline-block;
              font-weight: 800;
            }
          }

          input {
            color: black;
            font-size: 1.1rem;
            padding: 5px 15px;
            width: 70%;
            border: none;
            font-family: "Nunito Sans", sans-serif;
          }

          button {
            background: $red;
            color: white;
            font-weight: bold;
            font-family: "Raleway", sans-serif;
            margin: 20px auto 0;
            border: white 2px solid;
            font-size: 1.5rem;
            padding: 10px 30px;
            display: block;
            border-radius: 5px 5px 0px 5px;
            transition: 0.3s all;
            cursor: pointer;

            &:hover {
              background: white;
              color: black;
            }
          }

          .ant-select-selector {
            border: none;

            input {
              width: 100%;
              padding: 0 !important;
            }

            .ant-select-selection-placeholder {
              text-align: left;
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
  }

  // Responsive

  @include mobile-size {
    .hero-inner {
      padding: 50px 20px !important;

      .intro-text {
        h2 {
          font-size: 2.3rem !important;
        }

        h3 {
          font-size: 2rem !important;
        }

        p {
          margin-bottom: 0 !important;
          font-size: 1.5rem !important;
        }
      }
      .hero-cta {
        display: none !important;
      }
    }
  }
}
