#packages {
  margin: 50px 0;

  #packages {
    background-color: white;
    border-radius: 25px;
    padding-bottom: 20px;
    overflow: hidden;
  }

  .comparison-table {
    .table-row {
      display: flex;
      background: white;
      font-family: "Open Sans", sans-serif;

      &.footer-row {
        box-shadow: 0px 5.72px 1.43px rgba(1, 1, 1, 0.2);
        border: 3px solid #c43121;
      }

      &.heading-row {
        border: 3px solid #c43121;
      }

      .col {
        // margin: 5px 10px;
        padding: 0 15px;
        text-align: center;
        place-self: center;
        font-weight: 600;
        font-size: 0.9rem;
        margin: 0;
        border: 1px solid #8A8B8B;;
        height: 60px;
        display: flex;
        align-items: center;
        position: relative;

        &.col-head-first {
          width: 25%;
          margin-right: 0.5%;
        }

        &.col-head-midle {
          width: 25%;
          margin: 0 0.5%;
        }

        &.col-head-last {
          width: 25%;
          margin-left: 0.5%;
        }

        &.col-body {
          width: 24%;
          margin: 10px 0.5%;
          box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.4);
        }
      }

      .label {
        text-align: left !important;
        // margin: 0 !important;

        span {
          width: 100%;
          display: block;
          cursor: help;
          padding-left: 10px;
        }

        .help-icon {
          color: #c53628;
          margin-right: 5px;
          position: absolute;
          left: 5px;
          top: 5px;
        }
      }

      .no {
        // color: #8c8c8c;
        color: #575655;
        font-size: 1.3rem;
        font-weight: 900;
        position: absolute;
        left: 48%;
      }

      .yes,
      .col .info {
        color: #c53628;
        font-size: 1.3rem;
        font-weight: 900;
        width: 100% ;
      }

      &:nth-child(odd) .col {
        background: #BDD3DF;
      }
    }

    .heading-row {
      .col {
        background: #c42c1e !important;
        position: relative;
        border-color: #c42c1e;
      }

      h3 {
        font-size: 1.1rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.2em;
        margin-bottom: 30px;
        color: white;
        margin: 0;
      }
    }

    .price-row {
      .label {
        background: transparent !important;
        border: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;

        button {
          display: block;
          padding: 8px 30px;
          border: 2px solid #c53628;
          background: #c53628;
          color: white;
          font-weight: 800;
          cursor: pointer;
          margin: 0 5px;
          font-size: 1.2rem;
          width: 180px;
          opacity: 0.5;

          &.monthly {
            border-radius: 25px 0px 0px 25px;
          }
          &.annual {
            border-radius: 0px 25px 25px 0px;
          }
          &.active,
          &:hover {
            background: #c53628;
            color: white;
            border-color: #c53628 !important;
            opacity: 1;
          }
        }
      }

      .price-col {
        background: #BDD3DF !important;
        border: #8A8B8B 1px solid;
        box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);
        width: 23%;
        margin: 10px 1%;

        .price {
          color: black;
          font-size: 2rem;
          font-weight: 700;
          text-align: center;

          .old-price {
            font-size: 20px;
            display: block;
            text-decoration: line-through;
            margin-bottom: -10px;
          }

          sup {
            font-size: 1rem;
            top: -1em;
          }
        }
      }
    }

    .footer-row {
      .col {
        background: #c42c1e !important;
        position: relative;
        border-color: #c42c1e;
      }

      a {
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.2rem;
        display: block;
      }
    }

    .mobile-table {
      display: none;
    }
  }

  @include mobile-size {
    .mobile-table {
      display: block !important;

      h3 {
        font-size: 1.1rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.2em;
        margin-bottom: 30px;
        color: white;
        margin: 0;
        background: #c42c1e;
        text-align: center;
        padding: 10px;

        .price {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          font-size: 2rem;
          margin-bottom: 20px;

          .old-price {
            font-size: 1rem;
            padding-right: 15px;
            text-decoration: line-through;
          }
        }
      }

      .features-col {
        margin-bottom: 50px;
      }

      a {
        color: #c43121;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.2rem;
        display: block;
        background-color: #c42c1e;
        text-align: center;
        padding: 10px;

        &:hover {
          color: black;
        }
      }
    }

    .desktop-table {
      display: none !important;
    }

    .table-row {
      .col {
        width: 50% !important;
      }
    }

    .price-row {
      .price-col {
        display: none;
      }

      .label {
        text-align: center !important;
        width: 100% !important;
        margin-bottom: 15px !important;
      }
    }
  }
}
